.error-text {
  font-size: 0.75rem;
  color: #f799a7;
  margin: 4px 0 0;
}

.accordion-header {
  margin: 0px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.no-data-message {
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
  display: flex;
  align-items: center;
}